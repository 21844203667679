<template>
  <div
    style="text-align: center; vertical-align: middle; margin: 0 auto; padding: 60px 10px; "
  >
    <img :src="require('@/assets/other_images/xcx.jpg')" />
    <br />
    <p style="font-size: 28px">长按前往图中小程序</p>
  </div>
</template>

<script>
  export default {
    name: 'Yjk',
  }
</script>

<style scoped></style>
